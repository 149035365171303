<template>
  <div class="detail-work-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item>作品內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail"
          >{{ !show_detail ? "內容展開" : "內容收起" }}
        </el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>

      <el-form label-position="top" v-show="show_detail">
        <el-form-item label="中文作品名稱">
          <el-input :value="work_form.title_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文作品名稱">
          <el-input :value="work_form.title_zh" disabled></el-input>
        </el-form-item>

        <el-form-item label="啟用">
          <el-radio-group v-model="work_form.active" size="small">
            <el-radio :label="true" :border="true" disabled>啟用</el-radio>
            <el-radio :label="false" :border="true" disabled>停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="中文作品大小">
          <el-input :value="work_form.dimension_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文作品大小">
          <el-input :value="work_form.dimension_en" disabled></el-input>
        </el-form-item>

        <el-form-item label="作品展覽類別">
          <el-select :value="work_form.category_id" disabled>
            <el-option
              v-for="{ category_id, name_zh, name_en } in categories"
              :key="category_id"
              :value="category_id"
              :label="name_zh"
              style="width: 300px"
            >
              <span
                style="float: left; width: 120px; display: block"
                class="text-overflow"
                >{{ name_zh }}</span
              >
              <span class="text-overflow name">{{ name_en }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="時間場次">
          <div
            v-for="(s, index) in work_form.session"
            :key="index"
            style="margin: 8px 0"
          >
            <el-date-picker
              :value="s.start"
              type="datetime"
              placeholder="請選擇"
              disabled
            >
            </el-date-picker>

            <span style="display: inline-block; margin: 0 6px">至</span>

            <el-date-picker
              :value="s.end"
              type="datetime"
              placeholder="請選擇"
              disabled
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="作品種類" prop="type">
          <el-select :value="work_form.type" disabled>
            <el-option value="" label="未指定"></el-option>
            <el-option
              v-for="t in types"
              :key="t"
              :value="t"
              :label="t"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="作品年份" prop="year">
          <el-date-picker
            :value="work_form.year"
            type="year"
            placeholder="請選擇年份"
            disabled
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="作品結束年份" prop="end_year">
          <el-date-picker
            :value="work_form.end_year"
            type="year"
            placeholder="請選擇年份"
            disabled
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="展區位置" prop="location_id">
          <el-select
            :value="work_form.location_id"
            style="width: 300px"
            disabled
          >
            <el-option
              v-for="{ work_location_id, name_zh, name_en } in locations"
              :key="work_location_id"
              :value="work_location_id"
              :label="name_zh"
            >
              <span
                style="float: left; width: 120px; display: block"
                class="text-overflow"
                >{{ name_zh }}</span
              >
              <span class="text-overflow name">{{ name_en }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="中文媒材">
          <el-input :value="work_form.material_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文媒材">
          <el-input :value="work_form.material_en" disabled></el-input>
        </el-form-item>

        <el-form-item label="中文作品理念">
          <div ref="js-editor-proposal_zh"></div>
        </el-form-item>

        <el-form-item label="英文作品理念">
          <div ref="js-editor-proposal_en"></div>
        </el-form-item>

        <el-form-item
          label="中文互動文字說明"
          prop="interactive_description_zh"
        >
          <el-input
            type="textarea"
            v-model="work_form.interactive_description_zh"
            :rows="8"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item
          label="英文互動文字說明"
          prop="interactive_description_en"
        >
          <el-input
            type="textarea"
            v-model="work_form.interactive_description_en"
            :rows="8"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="中文備註">
          <el-input
            type="textarea"
            :value="work_form.note_zh"
            :rows="3"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="英文備註">
          <el-input
            type="textarea"
            :value="work_form.note_en"
            :rows="3"
            disabled
          ></el-input>
        </el-form-item>

        <el-divider></el-divider>

        <template v-for="val in [1, 2, 3, 4, 5]">
          <el-row :key="val">
            <el-col :span="8">
              <el-form-item :label="`作品圖片${val}`">
                <el-upload
                  :ref="`upload-${val}`"
                  class="work-uploader"
                  action=""
                  :show-file-list="false"
                  accept=".jpeg,.jpg,.png"
                  disabled
                >
                  <img
                    v-if="imageUrls[`url${val}`]"
                    :src="imageUrls[`url${val}`]"
                    alt="work"
                    class="work-image"
                  />
                  <i v-else class="el-icon-plus work-uploader-icon"></i>
                </el-upload>
                <el-button type="danger" size="small" disabled
                  >清除圖片
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="圖片中文描述">
                <el-input
                  type="textarea"
                  :rows="4"
                  :value="work_form[`photo_${val}_d_zh`]"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="圖片英文描述">
                <el-input
                  type="textarea"
                  :rows="4"
                  :value="work_form[`photo_${val}_d_en`]"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-divider></el-divider>

        <el-form-item prop="file_url">
          <span slot="label"
            >影片連結 <span class="tip">(*若多組請使用 , 隔開)</span></span
          >

          <el-input
            v-model="work_form.file_url"
            placeholder="請輸入 youtube 連結"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="作品線上呈現檔案" v-if="work_form.file">
          <iframe
            :src="work_form.file"
            sandbox="allow-same-origin"
            class="w-100 custom-iframe"
          ></iframe>
        </el-form-item>

        <el-form-item label="作品線上呈現檔案" prop="file" v-else>
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :auto-upload="false"
            disabled
          >
            <el-button slot="trigger" size="small" type="primary" disabled
              >上傳檔案
            </el-button>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button
              type="primary"
              @click="$router.push({ name: 'EditWork' })"
              >編輯</el-button
            >
          </div>
        </el-form-item>
      </el-form>

      <work-group-table
        :data="groups"
        @get:work="handleGetWork"
      ></work-group-table>

      <work-material-table
        :data="materials"
        @get:work="handleGetWork"
      ></work-material-table>

      <work-artist-table
        :data="artists"
        @get:work="handleGetWork"
      ></work-artist-table>

      <keyword-table
        :data="keywords"
        title="作品關鍵字列表"
        :create-method="createWorkKeywords"
        :delete-method="deleteWorkKeywords"
        @get:keywords="handleGetWork"
      ></keyword-table>
    </div>
  </div>
</template>

<script>
import { getWorkLocations } from "@/api/work-location";
import { getCategories } from "@/api/category";
import { getWork, createWorkKeywords, deleteWorkKeywords } from "@/api/work";
import types from "@/views/work/main/utils/types";
import WorkGroupTable from "./components/WorkGroupTable";
import WorkMaterialTable from "./components/WorkMaterialTable";
import WorkArtistTable from "./components/WorkArtistTable";
import KeywordTable from "@/components/Keyword/KeywordTable.vue";
import E from "wangeditor";

export default {
  name: "DetailWork",
  components: {
    WorkGroupTable,
    WorkMaterialTable,
    WorkArtistTable,
    KeywordTable,
  },
  data() {
    return {
      show_detail: false,
      work_form: {
        title_zh: "",
        title_en: "",
        dimension_zh: "",
        dimension_en: "",
        interactive_description_zh: "",
        interactive_description_en: "",
        category_id: "",
        active: true,
        type: "",
        session: [{ start: "", end: "" }],
        year: "",
        end_year: "",
        location_id: "",
        material_zh: "",
        material_en: "",
        proposal_zh: "",
        proposal_en: "",
        note_zh: "",
        note_en: "",
        photo_1: null,
        photo_1_d_zh: "",
        photo_1_d_en: "",
        photo_2: null,
        photo_2_d_zh: "",
        photo_2_d_en: "",
        photo_3: null,
        photo_3_d_zh: "",
        photo_3_d_en: "",
        photo_4: null,
        photo_4_d_zh: "",
        photo_4_d_en: "",
        photo_5: null,
        photo_5_d_zh: "",
        photo_5_d_en: "",
        file_url: "",
        file: "",
      },
      categories: [],
      locations: [],
      materials: [],
      types,
      imageUrls: {
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
      },
      groups: [],
      artists: [],
      keywords: [],
      keyword_params: {
        limit: 25,
        page: 0,
        search: "",
      },
      createWorkKeywords,
      deleteWorkKeywords,
    };
  },
  computed: {
    workId() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    await this.handleGetCategories();
    await this.handleGetWorkLocations();
    await this.handleGetWork();

    this.handleInitEditor();
  },
  methods: {
    async handleGetCategories() {
      this.categories = await getCategories();

      if (this.categories.length === 0) {
        await this.$alert("你尚未新增類別，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "CreateCategory" });
          },
        });
      }
    },
    async handleGetWorkLocations() {
      this.locations = await getWorkLocations();

      if (this.locations.length === 0) {
        await this.$alert("你尚未新增任何展區，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "CreateWorkLocation" });
          },
        });
      }
    },
    async handleGetWork() {
      const {
        work_en,
        work_zh,
        groups,
        artists,
        materials,
        location,
        category,
        keywords,
        ...res
      } = await getWork(this.workId, { relation: true });

      this.groups = groups;
      this.materials = materials;
      this.keywords = keywords;
      this.artists = artists;

      Object.keys(this.work_form).forEach((key) => {
        if (key.endsWith("_zh")) {
          const key_zh = key.split("_zh")[0]; // remove suffix to get real key

          if (key_zh in work_zh) {
            this.work_form[key] = work_zh[key_zh];
          }
        }

        if (key.endsWith("_en")) {
          const key_en = key.split("_en")[0]; // remove suffix to get real key

          if (key_en in work_en) {
            this.work_form[key] = work_en[key_en];
          }
        }
      });

      Object.keys(res).forEach((key) => {
        if (key.startsWith("photo_") && res[key]) {
          const index = key.split("_")[1];
          this.imageUrls[
            `url${index}`
          ] = `${process.env.VUE_APP_BASE_API}/${res[key]}`;
        } else if (key === "file") {
          if (res[key] !== null && res[key].length !== 0) {
            this.work_form[key] = `${process.env.VUE_APP_BASE_API}/${res[key]}`;
          }
        } else {
          if (key in this.work_form) {
            this.work_form[key] = res[key];
          }
        }
      });

      if (category) {
        this.work_form.category_id = category.category_id;
      }

      if (location) {
        this.work_form.location_id = location.work_location_id;
      }
    },
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith(["js-editor"])) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];

          editor.create();

          editor.disable();

          editor.txt.html(this.work_form[key]);
        }
      });
    },
    async handleChangeKeywordPage(page) {
      this.keyword_params.page = page - 1;

      await this.handleGetWorkKeywords();
    },
    async handleRedirect() {
      await this.$router.push({ name: "Work" });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-work-block {
  ::v-deep.work-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  ::v-deep.work-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  ::v-deep.work-image {
    width: 178px;
    height: 178px;
    display: block;
  }

  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }

    .name {
      float: right;
      color: #8492a6;
      font-size: 13px;
      width: 120px;
      display: block;
      text-align: right;
    }
  }

  .custom-iframe {
    border: 1px solid #d9d9d9;
  }

  ::v-deep .w-e-content-preview {
    background-color: #f5f7fa;
    cursor: not-allowed;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #c0c4cc;
    }
  }
}
</style>
