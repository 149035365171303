<template>
  <div class="work-artist-table-component">
    <div class="container">
      <table-title>
        <p>作品藝術家列表</p>

        <el-button type="success" @click="drawer_visible = true"
          >新增藝術家
        </el-button>
      </table-title>

      <el-table :data="data">
        <el-table-column label="ID" prop="artist_id" sortable></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="artist_zh.name"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="artist_en.name"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="100">
          <template v-slot="{ row }">
            <el-button
              type="danger"
              @click="handleDelete(row.artist_id)"
              size="mini"
              icon="el-icon-delete"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="新增藝術家"
      direction="rtl"
      size="600px"
      :visible.sync="drawer_visible"
      :before-close="handleCloseCreateDrawer"
    >
      <div class="artist-wrapper">
        <el-table
          ref="tableRef"
          :data="drawer_artists"
          @select="handleSelect"
          @select-all="handleSelect"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="artist_zh.name"
            label="中文名稱"
          ></el-table-column>
          <el-table-column
            prop="artist_en.name"
            label="英文名稱"
          ></el-table-column>
        </el-table>

        <div class="flex-end" style="margin-top: 20px">
          <el-button type="primary" @click="handleSubmit">確定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getArtistsPagination } from "@/api/artist";
import { createWorkArtists, deleteWorkArtist } from "@/api/work";

export default {
  name: "WorkArtistTable",
  props: {
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer_visible: false,
      drawer_artists: [],
      setup: {
        page: 0,
        limit: 200,
      },
      current_selection: [],
    };
  },
  computed: {
    workId() {
      return this.$route.params.id;
    },
  },
  watch: {
    async drawer_visible(val) {
      if (val) {
        await this.handleGetArtistsPagination();

        this.$nextTick(() => {
          this.data.forEach(({ artist_id }) => {
            this.$refs.tableRef.toggleRowSelection(
              this.drawer_artists.find(({ artist_id: id }) => id === artist_id),
              true
            );
          });
        });
      }
    },
  },
  methods: {
    async handleGetArtistsPagination() {
      const { results } = await getArtistsPagination(this.setup);

      this.drawer_artists = results;
    },
    handleDelete(artist_id) {
      this.$confirm("是否刪除藝術家", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteWorkArtist(this.workId, artist_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.$emit("get:work");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleSelect(selection) {
      this.current_selection = selection.map(({ artist_id }) => artist_id);
    },
    handleCloseCreateDrawer(done) {
      this.$confirm("確認關閉新增抽屜？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    async handleSubmit() {
      await createWorkArtists(this.workId, {
        artist_id_group: this.current_selection,
      });

      this.$message({
        type: "success",
        message: "修改成功",
      });

      this.$emit("get:work");

      this.drawer_visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-artist-table-component {
  .artist-wrapper {
    padding: 0 10px 20px;

    .button-block {
      margin-top: 20px;
    }
  }
}
</style>
