export default [
  "p5.js",
  "three.js",
  "website",
  "video",
  "360video",
  "video+pic",
  "360+pic",
  "pic carrousel",
];
