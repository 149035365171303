import { render, staticRenderFns } from "./WorkMaterialTable.vue?vue&type=template&id=2bdb2236&scoped=true&"
import script from "./WorkMaterialTable.vue?vue&type=script&lang=js&"
export * from "./WorkMaterialTable.vue?vue&type=script&lang=js&"
import style0 from "./WorkMaterialTable.vue?vue&type=style&index=0&id=2bdb2236&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bdb2236",
  null
  
)

export default component.exports