var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-groups-table-component"},[_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v("作品團隊列表")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.drawer_visible = true}}},[_vm._v("新增團體 ")])],1),_c('el-table',{attrs:{"data":_vm.data}},[_c('el-table-column',{attrs:{"label":"ID","prop":"group_id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticStyle:{"color":"#409eff"},attrs:{"to":{ name: 'DetailGroup', params: { id: row.group_id } }}},[_vm._v(_vm._s(row.group_id))])]}}])}),_c('el-table-column',{attrs:{"label":"團隊中文名稱","prop":"group_zh.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"團隊藝術家","prop":"artists","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},_vm._l((row.artists),function(ref,index){
var artist_zh = ref.artist_zh;
return _c('span',{key:index},[_vm._v(_vm._s(artist_zh.name)),(index !== row.artists.length - 1)?_c('span',[_vm._v("、")]):_vm._e()])}),0)]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"danger","size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row.group_id)}}},[_vm._v("刪除")])]}}])})],1)],1),_c('el-drawer',{attrs:{"title":"新增作品團隊","direction":"rtl","size":"600px","visible":_vm.drawer_visible,"before-close":_vm.handleCloseCreateDrawer},on:{"update:visible":function($event){_vm.drawer_visible=$event}}},[_c('div',{staticClass:"group-wrapper"},[_c('el-table',{ref:"tableRef",attrs:{"data":_vm.drawer_groups},on:{"select":_vm.handleSelect,"select-all":_vm.handleSelect}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"group_zh.name","label":"團隊中文名稱"}}),_c('el-table-column',{attrs:{"prop":"group_en.name","label":"團隊英文名稱"}})],1),_c('div',{staticClass:"flex-end",staticStyle:{"margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("確定")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }